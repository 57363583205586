import flattenedQueries from '@/.pre-build/flattened-queries.json';
import { convertStringToDocNode, getGqlString, graphServerUrl, mergeAdditionalHeaders } from '.';
import { DocumentNode, ApolloQueryResult } from '@apollo/client';
import Logger from '@/utils/logger';
import LZString from 'lz-string';

const logTruncationLen = parseInt(process.env.GRAPHQL_LOG_TRUNCATION_LEN || '-1');

const isCalledFromBrowser = typeof window !== 'undefined';
const logger = new Logger({ caller: 'utils.graphql.feedback' });

const truncateIfNeeded = (str: string) => {
  if (logTruncationLen > 0 && str.length > length) {
    return str.substring(0, logTruncationLen) + '...';
  }
  return str;
};

const getQueryName = (query: DocumentNode | string) => {
  // If we don't ignore TypeScript here, an error is thrown: `Property 'name' does not exist on type 'DefinitionNode'. Property 'name' does not exist on type 'SchemaDefinitionNode'.`
  // This is apparently because the type definitions for DocumentNode are not correct.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore-next-line
  return typeof query === 'string' ? '' : query?.definitions?.[0]?.name?.value ?? '';
};

const getHeadersForDebugFeedback = (additionalHeaders: object = {}) => {
  const merged = mergeAdditionalHeaders({}, additionalHeaders).headers;
  if (isCalledFromBrowser) {
    // If needed, redacting headers when running in-browser, as they may contain sensitive data
    return Object.keys(merged).length > 0 ? { _redacted: true } : {};
  }
  return merged;
};

const getFlattenedQuery = (query: string | DocumentNode): string => {
  if (typeof query !== 'string') {
    query = getGqlString(query) || '';
  }
  return (flattenedQueries as { [key: string]: string })[query.trim()] as string || query;
};

// Based on: https://github.com/apollographql/apollo-studio-community/discussions/127#discussioncomment-2152550
const getPlaygroundUrl = (query: DocumentNode | string, variables: object, headers: object) => {
  const queryAsString = getFlattenedQuery(query);

  // If imports are unresolved, we can't generate a useful URL
  if (queryAsString.indexOf('#import') >= 0) {
    return '';
  }

  const project = 'PBS-KIDS-GraphQl';
  const variant = 'prod';
  const includeCookies = false;

  const explorerURLState = LZString.compressToEncodedURIComponent(
    JSON.stringify({
      document: queryAsString.trim(),
      variables: JSON.stringify(variables, null, 2),
      headers: JSON.stringify(headers),
      includeCookies: String(includeCookies),
    }),
  );

  return `https://studio.apollographql.com/graph/${project}/explorer?explorerURLState=${explorerURLState}&variant=${variant}`;
};

const getQueryDebugInfo = async (
  headline: string,
  query: DocumentNode | string,
  variables: object,
  additionalHeaders: object,
  result?: object,
) => {
  const queryAsString = typeof query === 'string' ? query : getGqlString(query);
  const queryAsDocNode = typeof query === 'string' ? await convertStringToDocNode(query) : query;
  const headers = getHeadersForDebugFeedback(additionalHeaders);
  const playgroundUrl = getPlaygroundUrl(queryAsDocNode, variables, headers);

  headline = headline.replace(/\{queryName\}/g, getQueryName(queryAsDocNode) || '[unspecified]');

  const msg = [
    headline,
    `Queried:\n${graphServerUrl}`,
    `Try it out in Apollo Studio here:\n${playgroundUrl || '[Unavailable due to unresolved imports]'}`,
  ];

  if (result) {
    msg.push(`Returned data:\n${JSON.stringify(result, null, 2)}`);
  }

  if (!playgroundUrl) {
    msg.push(`Operation:\n${queryAsString}`);
  }

  if (Object.keys(variables).length > 0) {
    msg.push(`Variables:\n${JSON.stringify(variables, null, 2)}`);
  }

  if (Object.keys(headers).length > 0) {
    msg.push(`Headers:\n${JSON.stringify(headers, null, 2)}`);
  }

  return truncateIfNeeded( msg.join('\n\n') );
};

const throwDataIssueError = async (
  message: string,
  query: DocumentNode | string,
  variables: object,
  queryResult: object,
  additionalHeaders: object = {},
) => {
  throw new Error(
    await getQueryDebugInfo(message, query, variables, additionalHeaders, queryResult),
  );
};

const logDataIssueWarning = async (
  message: string,
  query: DocumentNode | string,
  variables: object,
  queryResult: object,
  additionalHeaders: object = {},
) => {
  logger.warn(
    await getQueryDebugInfo(message, query, variables, additionalHeaders, queryResult),
  );
};

const getHumanReadableErrorMessage = async (
  response: ApolloQueryResult<object|undefined>,
  query: DocumentNode | string,
  variables?: object,
  additionalHeaders?: object,
) => {
  const errors = response.errors || response.error;
  return getQueryDebugInfo('Error running query {queryName}.', query, variables || {}, additionalHeaders || {}, errors ? { errors } : response);
};

const completionMessage = async (
  cached: boolean | null,
  startTime: number,
  query: DocumentNode | string,
  variables?: object,
  additionalHeaders?: object,
) => {
  const duration = Date.now() - startTime;

  const getSummary = async (speed: string) => {
    const headline = cached === null ?
      `Returning ${speed} response after ${duration.toLocaleString()}ms for query {queryName}.` :
      `Returning ${speed} ${cached ? 'cached' : 'uncached'} response after ${duration.toLocaleString()}ms for query {queryName}.`;

    return getQueryDebugInfo(headline, query, variables || {}, additionalHeaders || {});
  };

  if (duration > 2000) {
    logger.info( await getSummary('🦥🦥🦥 VERY SLOW 🦥🦥🦥') );
  } else if (duration > 750) {
    logger.info( await getSummary('🦥 SLOW 🦥') );
  }
};

export {
  completionMessage,
  getHumanReadableErrorMessage,
  logDataIssueWarning,
  logger,
  throwDataIssueError,
};
